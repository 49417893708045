import React, { useState, useEffect } from "react";
import { Grid, Button, TextField, Snackbar, Alert, Typography, IconButton } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";

const Documents = ({ eventID, callerId }) => {
    const port = process.env.REACT_APP_API_KEY;
    const accessToken = localStorage.getItem("token");
    const [lab, setLab] = useState(null);
    const [prescription, setPrescription] = useState(null);
    const [dressing, setDressing] = useState(null);
    const [uploadedDocs, setUploadedDocs] = useState([]);
    const [snackbar, setSnackbar] = useState({ open: false, message: "", severity: "success" });

    useEffect(() => {
        const fetchUploadedDocs = async () => {
            try {
                const response = await fetch(`${port}/web/upload_documents_get/${eventID}/`, {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                });
                const result = await response.json();
                if (response.ok) {
                    setUploadedDocs(result);
                }
            } catch (error) {
                console.error("Error fetching uploaded documents:", error);
            }
        };
        fetchUploadedDocs();
    }, [eventID, port, accessToken]);

    const handleFileChange = (e) => {
        const { name, files } = e.target;
        if (files.length > 0) {
            const file = files[0];
            if (name === "lab_reports") setLab(file);
            if (name === "prescription") setPrescription(file);
            if (name === "dressing") setDressing(file);
        }
    };

    const handleSubmit = async () => {
        if (!lab && !prescription && !dressing) {
            setSnackbar({ open: true, message: "Please select at least one file to upload!", severity: "error" });
            return;
        }

        const formData = new FormData();
        formData.append("agg_sp_pt_id", callerId);
        formData.append("status", 1);
        formData.append("eve_id", eventID);
        if (lab) formData.append("lab_reports", lab);
        if (prescription) formData.append("prescription", prescription);
        if (dressing) formData.append("dressing", dressing);

        try {
            const response = await fetch(`${port}/web/upload_documents/${eventID}/`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
                body: formData,
            });

            const result = await response.json();
            if (!response.ok) {
                setSnackbar({ open: true, message: result.message || "Upload failed!", severity: "error" });
                return;
            }
            setSnackbar({ open: true, message: "File uploaded successfully!", severity: "success" });
        } catch (error) {
            setSnackbar({ open: true, message: `Error: ${error.message}`, severity: "error" });
        }
    };

    return (
        <Grid>
            <Grid container spacing={2} padding={2} sx={{ marginTop: "0.5em" }}>
                {[{ label: "Lab Report", name: "lab_reports", state: lab }, { label: "Prescription", name: "prescription", state: prescription }, { label: "Dressing", name: "dressing", state: dressing }].map(({ label, name }) => (
                    <Grid item lg={4} sm={4} xs={12} key={name}>
                        <TextField
                            required
                            label={label}
                            id={name}
                            name={name}
                            type="file"
                            size="small"
                            fullWidth
                            onChange={handleFileChange}
                            sx={{ '& input': { fontSize: '14px' } }}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ accept: ".doc,.docx,.pdf,.csv" }}
                        />
                    </Grid>
                ))}
            </Grid>

            <Grid container justifyContent="center" padding={2}>
                <Button variant="contained" size="medium" onClick={handleSubmit} sx={{ fontSize: "1rem" }}>
                    Submit
                </Button>
            </Grid>
            <hr />

            <Typography variant="body1" sx={{ fontSize: "1rem" }}>
                Uploaded Documents
            </Typography>

            <Grid container spacing={2} padding={2}>
                {uploadedDocs.map((doc) => (
                    <Grid item xs={12} key={doc.doc_id}>
                        {doc.prescription && (
                            <Typography>
                                Prescription:
                                <IconButton component="a" href={`${port}${doc.prescription}`} download="prescription.pdf" target="_blank" rel="noopener noreferrer">
                                    <DownloadIcon />
                                </IconButton>
                            </Typography>
                        )}
                        {doc.lab_reports && (
                            <Typography>
                                Lab Report:
                                <IconButton component="a" href={`${port}${doc.lab_reports}`} download="lab_report.pdf" target="_blank" rel="noopener noreferrer">
                                    <DownloadIcon />
                                </IconButton>
                            </Typography>
                        )}
                        {doc.dressing && (
                            <Typography>
                                Dressing:
                                <IconButton component="a" href={`${port}${doc.dressing}`} download="dressing.pdf" target="_blank" rel="noopener noreferrer">
                                    <DownloadIcon />
                                </IconButton>
                            </Typography>
                        )}
                    </Grid>
                ))}
            </Grid>

            <Snackbar
                open={snackbar.open}
                autoHideDuration={3000}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
                <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} variant="filled">
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Grid>
    );
};

export default Documents;
